<template>
    <transition name="alert">
        <section class="p-alert" v-show="show">
            
            <article class="form" v-if="!success">

                <div class="close" @click="onClose()">×</div>

                <div class="text">
                    <h2>Узнать размер скидки</h2>
                    <p>Введите ваши контактные данные. Наш менеджер свяжется с Вами в ближайшее время!</p>
                </div>

                <form class="send" @submit="onSubmit">
                    <input type="text" name="name" v-model="form.name" placeholder="Ваше имя" >
                    <input type="phone" name="phone" v-model="form.phone" placeholder="Ваш телефон">
                    <input type="phone" name="email" v-model="form.email" placeholder="Ваш Email">

                    <input type="submit" value="Оставить заявку">
                    
                </form>

            </article>

            <article class="success" v-else>
                <div class="close" @click="onClose()">×</div>

                <div class="text">
                    <h2>Запрос был успешно отправлен</h2>
                    <p>В ближайшее время с вами свяжется наш специалист, удостоверьтесь что данные были введены коректны</p>
                </div>

            </article>
      
        </section>
    </transition>
</template>

<script>

export default {
    name: "Alert",
    props: ["show"],

    data: () => {
        return {
            animation: false,
            form: {},
            success: false
        };
    },

    methods: {

        onClose() {
            this.success = false;
            this.$emit("onChange", false);
        },

        onSubmit(e) {
            e.preventDefault();

            let self = this;

            for (let prop in self.form) {

                if (self.form[prop] === "") {
                console.log(self.form[prop]);
                return;
                }
            }

            axios.post("/feedback.php", self.form).then(function (response) {
                this.form = {};
                self.success = response.status == 200 ? true : false;
            });
        }

    }

}
</script>