<template>
  <section class="p-calculator" id="calculator">

    <div class="container">

      <div class="header wow animate__fadeInRight">
        <h2>Калькулятор цен</h2>
        <p>У нас вы можете подсчитать примерную стоимость закупки и укладки брусчатки</p>
      </div>

      <article class="choose wow animate__fadeInUp">

        <div class="text">
          <h3>{{ product.name.short }} ({{ getColorText | tolower }})</h3>
          <p class="price">{{ product.price | currency }} ₸</p>

          <div class="count">
            <h4>Количество:</h4>

            <div class="form">
              <button @click="onChangeCount(-1)">
                <img draggable="false" src="./../../assets/promo/icon-minus.png" alt="Minus" />
              </button>
              <input name="number" type="text" v-model.number="choose.count">
              <button @click="onChangeCount(1)">
                <img draggable="false" src="./../../assets/promo/icon-plus.png" alt="Plus" />
              </button>
            </div>
          </div>

          <div class="color">
            <h4>Цвет:</h4>

            <div class="type" >
              <div
                v-for="(color, index) in products[choose.product].colors"
                :key="index"
                @click="choose.color = index"
                :class="{choose: choose.color === index ? true : false}"
                :style="{backgroundColor: color.style}">
              </div>
            </div>
          </div>

          <button class="add"  @click="onSelect()">Добавить</button>
          
        </div>

        <div class="image">
          <img :src="getImage" alt="Product" />

          <div class="border">

            <div class="switcher">
              <p class="prev" :style="{opacity: (choose.product === 0) ? '0' : '1'}" @click="onSwitchImage(-1)">
                <img draggable="false" src="./../../assets/promo/icon-arrow.png" alt="Arrow" />
              </p>
              <p class="next" :style="{opacity: (products.length - 1 === choose.product) ? '0' : '1'}" @click="onSwitchImage(1)">
                <img draggable="false" src="./../../assets/promo/icon-arrow.png" alt="Arrow" />
              </p>
            </div>
          </div>
        </div>

      </article>

      <article class="total wow animate__fadeInUp">

        <div class="header" v-if="selected.length > 0">
          <h3>Сумма заказа</h3>
          <span class="line"></span>
        </div>

        <section class="table" v-if="selected.length > 0">
        
          <div class="entities">
            <p>Продукт</p>
            <p>Количество</p>
            <p>Цвет</p>
            <p>Цена</p>
            <p>Удалить</p>
          </div>

          <div
            class="product"
            v-for="(item, index) in selected"
            :key="index"
          >
            <p>{{ item.name }}</p>
            <p>{{ item.count }}</p>
            <p>
              <span :style="{backgroundColor: item.color}"></span>
            </p>
            <p>{{ item.price | currency }} ₸</p>
            <p>
              <button @click="selected.splice(index, 1)" class="delete">
                <img src="./../../assets/promo/icon-delete.png" alt="Delete" />
              </button>
            </p>
          </div>

          <div class="total">
            <p>Сумма</p>
            <p>{{ getTotalCount }}</p>
            <p></p>
            <p>{{ getTotalPrice | currency }} ₸</p>
            <p>
              <button @click="selected = []" class="delete">
                <img src="./../../assets/promo/icon-delete.png" alt="Delete" />
              </button>
            </p>
          </div>

        </section>
        
        <section v-else class="empty">
          <div class="header">
            <h3>Козина пуста</h3>
            <p>Что бы начать подсчёт добавите в корзину товар.</p>
          </div>
        </section>

      </article>

    </div>

  </section>
</template>

<script>

export default {
  name: "Calculator",

  data: () => {
    return {
      choose: {
        product: 0,
        color: 0,
        count: 1,
      },
      selected: []
    }
  },

  methods: {

    restoreToDefault() {
      this.choose.product = 0;
      this.choose.color = 0;
      this.choose.count = 1;
    },

    onSwitchImage(index) {
      if (this.choose.product + index < 0 || this.choose.product + index > this.products.length - 1) {
        return;
      } else {
        this.choose.product += index;
      }
    },

    onChangeCount(index) {

      if (this.choose.count + index > 0) {
        this.choose.count += parseInt(index);
      }
    },

    onSelect() {

      let self = this,
          find = false;

      if (isNaN(parseInt(this.choose.count)) || this.choose.count === "") {
        return;

        this.choose.count = 1;
      }

      this.selected.forEach(function (item, index) {
        if (item.name == self.product.name.short && item.color === self.product.colors[self.choose.color].style) { 
          item.price += parseInt(self.product.price) * parseInt(self.choose.count);
          item.count += parseInt(self.choose.count);

          find = true;
        }
      });

      if (find === false || this.selected.length === 0) {

        this.selected.push({
          color: this.product.colors[this.choose.color].style,
          count: this.choose.count,
          name: this.product.name.short,
          price: this.product.price * this.choose.count
       });
      }

      this.restoreToDefault()
    }
  },

  computed: {

    getImage() {
      return require(`./../../assets/promo/${this.products[this.choose.product].calc}`);
    },

    product() {
      return this.products[this.choose.product];
    },

    getColorText() {
      return this.products[this.choose.product].colors[this.choose.color].text;
    },

    isNumber() {
      return (isNaN(parseInt(this.choose.count))) ? true : false;
    },

    getTotalCount() {
      let count = 0;

      this.selected.forEach(function (value, index) {
        count += value.count;
      });

      return count;
    },

    getTotalPrice() {
      let price = 0;

      this.selected.forEach(function (value, index) {
        price += value.price;
      });

      return price;
    }

  },

  filters: {

    tolower(value) {
      return value.toLowerCase();
    },

    currency(value) {
      return parseInt(value).toLocaleString('ru-RU');
    }

  },

  created() {
    this.products = this.$store.getters.getProducts;
  }
}
</script>