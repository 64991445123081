<template>
  <section class="advantages wow animate__fadeInLeft" id="advantages">

    <article class="column">
      <div class="text-block">
        <h3>{{ getMainColumn.header }}</h3>
        <p>{{ getMainColumn.text }}</p>
       </div>

      <slider-switcher
        :total="getSliderCount"
        @slideChange="onChangeDescription"
      ></slider-switcher>    
    </article>

    <article class="column"
      v-for="(item, index) in getAdvantagesColumn"
      :key="index + 1"
    >

      <template v-if="index & 1">
        <div class="text-block">
          <h3 v-if="item.header != ''">{{ item.header }}</h3>
          <p v-if="item.text != ''">{{ item.text }}</p>
        </div>

        <div class="image-block"
          :style="getImgStyle(item.img)"
        ></div>          
      </template>

      <template v-else>
        <div class="image-block"
          :style="getImgStyle(item.img)"
        ></div>

        <div class="text-block">
          <h3 v-if="item.header != ''">{{ item.header }}</h3>
          <p v-if="item.text != ''">{{ item.text }}</p>
        </div>
      </template>

    </article>

  </section>
</template>

<script>

import SliderSwitcher from './SliderSwitcher.vue';

export default {

  data() {

    return {
      current: 1,
    }

  },

  methods: {
    getImgStyle(src) {
      return {
        backgroundImage: "url(" + require('./../../assets/main/advant/' + src) + ")"
      };
    },
    onChangeDescription(current) {
      this.current = current;
    },
  },

  computed: {
    getAdvantagesColumn() {
      return this.$store.getters.advantages[this.current - 1].column;
    },
    getMainColumn() {
      let column = this.$store.getters.advantages[this.current - 1];

      return {
        header: column.header,
        text: column.text
      };
    },
    getSliderCount() {
      return this.$store.getters.advantages.length;
    }
  },

 components: {
   SliderSwitcher
 }

};
</script>