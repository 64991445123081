export default {

  state: {
    content: {
      header: "О компании",
      text1: "Компания «Камни Азии» более 7 лет выпускает брусчатку «Каменный ковер». «Каменный ковер» это голландский бренд «Carpet Stones», который известен и применяется во всем мире и теперь выпускается в Казахстане и России. Наша компания является единственным представителем этой технологии в Казахстане. Выпускаемая нами продукция по современной ноу-хау технологии «Carpet Stones» может найти свое успешное применение в строительстве и при благоустройстве любой территории.",
      text2: "Впервые в Казахстане, наше предприятие освоило производство дорожной брусчатки (искусственной), которая изготавливается по запатентованной технологии \"Carpet Stones\" (Нидерланды).",
      text3: "Задача нашей компании максимально облегчить процесс установки брусчатки для наших клиентов. Мы обслуживаем клиентов разного уровня сложности: от частных домов до государственных проектов. Мы готовы на общение с клиентом и обсуждение их потребностей с целью дать максимально лучшие решения.",
      text4: "Ежегодно мы развиваем нашу продукцию, мы следим за новыми технологиями и постоянно готовы к новшеству. Мы нацелены на постоянный рост и развитие, как производства продукции, так и профессионализма работников."
    }
  },

  getters: {
    content(state) {
      return state.content;
    }
  }
  
};