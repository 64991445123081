<template>
  <header class="p-header">

    <div class="container">

      <section class="top">
        <div class="logo">
          <img draggable="false" src="./../../assets/promo/logo-header.png" alt="logo" />
        </div>

        <div class="contact">

          <p class="phone">
            <span class="line"></span>
            <a draggable="false" class="text" href="tel:+37128469220">8 (701) 3035777</a>
          </p>

          <p class="button">
            <button @click="onShow()">Заказать звонок</button>
            <span class="line"></span>
          </p>
          
        </div>
      </section>

      <section class="content">

        <nav class="menu">

          <ul class="links">
            <li><a href="#specifications">Наша специализация</a></li>
            <li><a href="#calculator">Калькулятор цен</a></li>
            <li><a href="#about">Кто мы такие</a></li>
            <li><a href="#guarantee">Гарантии</a></li>
            <li><a href="#stage">Этапы работы</a></li>
            <li><a href="#contact">Контакты</a></li>
          </ul>

        </nav>

        <div class="text">
          <h1>Продажа и укладка</h1>
          <h3>брусчатка на гибких связях разных цветов и форм с гарантией от 15 лет</h3>
          <p>Получите спецпредложение на укладку и комплектующие при заказе любого вида пластов</p>

          <button @click="onShow()" class="discount">
            <span>Узнать размер скидки</span>
          </button>
        </div>

      </section>
      
    </div>

  </header>
</template>

<script>

export default {
  name: "Header",

  methods: {
    onShow() {
      this.$emit("onChange", true);
    }
  }
}

</script>