<template>
    <section class="p-stage" id="stage">

        <div class="container">

            <div class="header wow animate__fadeInLeft">
                <h2>5</h2>
                <div class="wrapper">
                    <p class="main">Этапов</p>
                    <p class="sub-main">работы с нами</p>
                </div>
            </div>

            <div class="arrow"></div>

            <ul class="list wow animate__fadeInRight">
                <li>
                    <h3>1</h3>
                    <div class="text">
                        <h4>Консутльтация</h4>
                        <p>помогаем вам выбрать брусчатку, подходящие вспомогательные материалы и комплектующие</p>
                    </div>
                </li>
                
                <li>
                    <h3>2</h3>
                    <div class="text">
                        <h4>Смета</h4>
                        <p>рассчитываем полную стоимость проекта и готовим подробную смету по всем позициям</p>
                    </div>
                </li>

                <li>
                    <h3>3</h3>
                    <div class="text">
                        <h4>Доставка</h4>
                        <p>привозим все необходимое быстро и бесплатно. Если материала нет на складе начинаем производство</p>
                    </div>
                </li>

                <li>
                    <h3>4</h3>
                    <div class="text">
                        <h4>Подготовка</h4>
                        <p>Подготавливаем территорию для укладки брусчатки, выравниваем почву</p>
                    </div>
                </li>

                <li>
                    <h3>5</h3>
                    <div class="text">
                        <h4>Укладка</h4>
                        <p>укладываем брусчатку и закачиваем работы, после чего вы забываете о брусчатке минимум на 15 лет</p>
                    </div>
                </li>
            </ul>

        </div>

        <div class="container">
            <div class="wrapper">
                <button class="request" @click="onShow()">Оставить заявку</button>
            </div>
        </div>

    </section>
</template>

<script>

export default {
    name: "Stage",

    methods: {
        onShow() {
            this.$emit("onChange", true);
        }
    }
}

</script>