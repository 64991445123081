<template>
  <footer class="footer" id="price">

    <p class="copyright">
      {{ fullYear }} &copy; Copyright
    </p>

    <ul class="menu">
      <li
        v-for="(link, index) in getLinks"
        :key="index + 1"
      >
        <a :href="link.href" @click="callBackAnchor(link.func)">{{ link.text }}</a>
      </li>
    </ul>

  </footer>
</template>

<script>
export default {

  methods: {
    onToStowage() {
      this.$store.commit('hide');
    },
    callBackAnchor(value) {
      if (value == 'onToStowage') {
        this.onToStowage();
      }
    }
  },

  computed: {
    fullYear() {
      return new Date().getFullYear();
    },
    getLinks() {
      return this.$store.getters.links;
    }
  }

};
</script>