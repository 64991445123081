import Vue from 'vue';
import Router from 'vue-router';

import Main from './components/Main/App.vue';
import ErrorPage from './components/Error/ErrorPage.vue';
import Promo from './components/Promo/App.vue';

Vue.use(Router);

export default new Router({
  history: 'hash',
  routes: [
    {
      path: '/',
      component: Main,
    },
    {
      path: '/promo',
      component: Promo
    },
    {
      path: '*',
      component: ErrorPage
    }
  ]
});