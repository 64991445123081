<template>
  <div id="app">
    <Alert :show="alert" @onChange="onChange"></Alert>
    <Header @onChange="onChange"></Header>
    <Specifications @onChange="onChange"></Specifications>
    <Calculator></Calculator>
    <About></About>
    <Call></Call>
    <Guarantee @onChange="onChange"></Guarantee>
    <Stage @onChange="onChange"></Stage>
    <Contact></Contact>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Specifications from "./Specifications.vue";
import Calculator from "./Calculator.vue";
import About from "./About.vue";
import Call from "./Call.vue";
import Guarantee from "./Guarantee.vue";
import Stage from "./Stage.vue";
import Contact from "./Contact.vue";
import Footer from "./Footer.vue";

import Alert from "./Alert";

function disableScroll() {  
	let scrollTop = document.documentElement.scrollTop; 
	let scrollLeft = document.documentElement.scrollLeft;

	window.onscroll = function() { 

		window.scrollTo({
      top: scrollTop,
      left: scrollLeft
    }); 
	}; 
} 

function enableScroll() { 
	window.onscroll = function() {}; 
} 

export default {
  name: "App",

  data: () => {
    return {
      alert: false
    }
  },

  methods: {

    onChange(bool) {
      if (bool) {
        document.documentElement.classList.add("alert");
        disableScroll();
      } else {
        document.documentElement.classList.remove("alert");
        enableScroll();
      }

      this.alert = bool;
    }

  },

  mounted() {
    new WOW({boxClass: 'wow', resetAnimation: true, animateClass: 'animate__animated'}).init();
  },

  components: {
    Header,
    Specifications,
    Calculator,
    About,
    Call,
    Guarantee,
    Stage,
    Contact,
    Footer,
    Alert
  }
}
</script>