export default {

  state: {
    cards: [
      {
        header: "Простота и скорость",
        text: "Каменный Ковер - это новая тротуарная брусчатка, ею смогут воспользоваться, как опытные специалисты, так и каждый, желающий изменить к лучшему внешний вид любой местности. Вы сможете как сами, просто и быстро уложить любой рисунок, так и обратиться к нам за услугой по укладке брусчатки. Ваша работа будет удивлять ваших друзей!",
        column: [
          {
            header: "Уникальный материал",
            text: "Появилась надобность благоустроить Вашу местность… Как насчет того, чтобы использовать лучшее из современных технологий? Мы предлагаем Вашему вниманию новый вид изделий для укладки (мощения) любых видов проектов. Область применения безгранична и зависит только от многообразия Ваших идей. Сделайте свой ландшафт высокого уровня!",
            img: "1.jpg"
          }, {
            header: "Безграничная возможность",
            text: "Благодаря гибким связям можно создать любой рисунок из изгибов, кругов, квадратов и других фигур. Пласты можно укладывать в круговой, изогнутой, веерообразной и прямолинейной форме, а также разделять пласты на небольшие сегменты, что также существенно увеличивает разнообразие узоров, как по рисунку, так и по цвету. Все модели полностью совместимы!",
            img: "2.jpg"
          }, {
            header: "Для автодорог и тротуаров",
            text: "Новое тротуарное покрытие Каменный ковёр даст вам больше вариантов в создании шедевра в проектах разного типа: тротуары, дорожки в саду, внутренние дворики, открытые кафе с возможностью использования для движения пешеходов, так и дороги, площади, мостовые для движения автомобилей. Создайте собственное эксклюзивное тротуарное решение!",
            img: "3.jpg"
          }
        ]
      },
  
      {
        header: "Длительная эксплуатация",
        text: "Мы производим изделия с высокими качественными показателями, которые имеют соответствующее качество с гарантией 15 лет и высокий уровень нагрузки. Наша продукция произведет на Вас большое впечатление, и мы убеждены: Вы найдете её неотразимой!",
        column: [
          {
            header: "Разработка проекта",
            text: "Просто, позвоните нам - мы приедем к Вам в любое удобное для Вас время. Наша компания готова помочь вам в выборе идеальной брусчатки. Наши специалисты сделают необходимые замеры для разработки дизайнерского проекта и расчёта необходимого объёма продукции. Мы производим, а вы наслаждаетесь!",
            img: "4.jpg"
          }, {
            header: "Услуги по укладке",
            text: "Для обеспечения надлежащего внешнего вида и долговечности тротуарного покрытия необходимо позаботиться об основании, убедиться в том, что оно сконструировано и устроено должным образом и выдержит требуемую нагрузку. Закажите нам услугу по установке брусчатки, что значительно улучшит качество работ, к тому же с гарантией качества и в срок.",
            img: "5.jpg"
          }, {
            header: "",
            text: "",
            img: "6.jpg"
          }
        ]
      }
    ]
  },

  getters: {
    advantages(state) {
      return state.cards;
    }
  }

};