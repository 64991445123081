export default {

  state: {
    price: [
      {
        details: {
          header: "Прямойлинейный пласт",
          text: [
            "Размер (см): 118 х 38 х 4",
            "Площадь (кв.м): 0.45",
            "Масса пласта (кг): 31",
            "Кол-во пластов на поддоне (шт): 30"           
          ]
        },
        slider: {
          src: "1.jpg",
        },
        layers: {
          src: "e41.png",
          amount: "meters",
          price: [
            {
              srcPart: "white",
              name: "Серый",
              color: "#C8C3BC",
              price: {
                rub: "640",
                tenge: "3200"
              }
            },
            {
              srcPart: "black",
              name: "Чёрный",
              color: "#555a5f",
              price: {
                rub: "760",
                tenge: "3800"
              }
            },
            {
              srcPart: "brown",
              name: "Коричневый",
              color: "#884535",
              price: {
                rub: "760",
                tenge: "3800"
              }
            },
            {
              srcPart: "orange",
              name: "Оранжевый",
              color: "#F3892B",
              price: {
                rub: "760",
                tenge: "3800"
              }
            },
            {
              srcPart: "red",
              name: "Красный",
              color: "#E32636",
              price: {
                rub: "760",
                tenge: "3800"
              }
            }
          ]
        }
      },
      {
        details: {
          header: "Веерный пласт",
          text: [
            "Размер (см): 118 х 38 х 4",
            "Площадь (кв.м): 0.45",
            "Масса пласта (кг): 31",
            "Кол-во пластов на поддоне (шт): 30"
          ],
        },
        slider: {
          src: "2.jpg",
        },
        layers: {
          src: "e42.png",
          amount: "meters",
          price: [
            {
              srcPart: "white",
              name: "Серый",
              color: "#C8C3BC",
              price: {
                rub: "660",
                tenge: "3300"
              }
            },
            {
              srcPart: "black",
              name: "Чёрный",
              color: "#555a5f",
              price: {
                rub: "780",
                tenge: "3900"
              }
            },
            {
              srcPart: "brown",
              name: "Коричневый",
              color: "#884535",
              price: {
                rub: "780",
                tenge: "3900"
              }
            },
            {
              srcPart: "orange",
              name: "Оранжевый",
              color: "#F3892B",
              price: {
                rub: "780",
                tenge: "3900"
              }
            },
            {
              srcPart: "red",
              name: "Красный",
              color: "#E32636",
              price: {
                rub: "780",
                tenge: "3900"
              }
            }
          ]
        }
      },
      {
        details: {
          header: "Элемент веерного пласта",
          text: [
            "Размер (см): 118 х 38 х 4",
            "Площадь (кв.м): 0.45",
            "Масса пласта (кг): 31",
            "Кол-во пластов на поддоне (шт): 30"
          ],
        },
        slider: {
          src: "3.jpg",
        },
        layers: {
          src: "e43.png",
          amount: "meters",
          price: [
            {
              srcPart: "white",
              name: "Серый",
              color: "#C8C3BC",
              price: {
                rub: "660",
                tenge: "3300"
              }
            },
            {
              srcPart: "black",
              name: "Чёрный",
              color: "#555a5f",
              price: {
                rub: "780",
                tenge: "3900"
              }
            },
            {
              srcPart: "brown",
              name: "Коричневый",
              color: "#884535",
              price: {
                rub: "780",
                tenge: "3900"
              }
            },
            {
              srcPart: "orange",
              name: "Оранжевый",
              color: "#F3892B",
              price: {
                rub: "780",
                tenge: "3900"
              }
            },
            {
              srcPart: "red",
              name: "Красный",
              color: "#E32636",
              price: {
                rub: "780",
                tenge: "3900"
              }
            }
          ]
        }
      },
      {
        details: {
          header: "Поребрик (Бордюр)",
          text: [
            "Размер (см): 42 х 20 х 8",
            "Масса (кг): 13",
            "Кол-во бордюров на поддоне (шт): 78"
          ]
        },
        slider: {
          src: "4.jpg",
        },
        layers: {
          src: "e46.png",
          amount: "piece",
          price: [
            {
              srcPart: "white",
              name: "Серый",
              color: "#C8C3BC",
              price: {
                rub: "130",
                tenge: "650"
              }
            },
            {
              srcPart: "black",
              name: "Чёрный",
              color: "#555a5f",
              price: {
                rub: "150",
                tenge: "750"
              }
            },
            {
              srcPart: "brown",
              name: "Коричневый",
              color: "#884535",
              price: {
                rub: "150",
                tenge: "750"
              }
            },
            {
              srcPart: "orange",
              name: "Оранжевый",
              color: "#F3892B",
              price: {
                rub: "150",
                tenge: "750"
              }
            },
            {
              srcPart: "red",
              name: "Красный",
              color: "#E32636",
              price: {
                rub: "150",
                tenge: "750"
              }
            }
          ]
        }
      },
      {
        details: {
          header: "Полукруг",
          text: [
            "Размер (см): 118 х 60 х 4",
            "Площадь (кв.м): 0.79",
            "Масса пласта (кг): 48",
            "Кол-во пластов на поддоне (шт): 16"
          ],
        },
        slider: {
          src: "5.jpg",
        },
        layers: {
          src: "e44.png",
          amount: "meters",
          price: [
            {
              srcPart: "white",
              name: "Серый",
              color: "#C8C3BC",
              price: {
                rub: "680",
                tenge: "3400"
              }
            },
            {
              srcPart: "black",
              name: "Чёрный",
              color: "#555a5f",
              price: {
                rub: "800",
                tenge: "4000"
              }
            },
            {
              srcPart: "brown",
              name: "Коричневый",
              color: "#884535",
              price: {
                rub: "800",
                tenge: "4000"
              }
            },
            {
              srcPart: "orange",
              name: "Оранжевый",
              color: "#F3892B",
              price: {
                rub: "800",
                tenge: "4000"
              }
            },
            {
              srcPart: "red",
              name: "Красный",
              color: "#E32636",
              price: {
                rub: "800",
                tenge: "4000"
              }
            }
          ]
        }
      }
    ],

    currency: {
      RUB: 'rub',
      KZT: 'tenge'
    }
  },

  getters: {
    priceList(state) {
      return state.price;
    },

    currency(state) {
      return state.currency;
    }
  }
} 

/*
      {
        details: {
          header: "Прямолинейный пласт (большой)",
          text: [
            "Размер (см): 120 х 40 х 4",
            "Масса пласта (кг): 32",
            "Кол-во пластов на поддоне (шт): 18"
          ],
        },
        slider: {
          src: "6.jpg",
        },
        layers: {
          src: "e48.png",
          amount: "meters",
          price: [
            {
              srcPart: "white",
              name: "Серый",
              color: "#C8C3BC",
              price: {
                rub: "480",
                tenge: "2900"
              }
            },
            {
              srcPart: "black",
              name: "Чёрный",
              color: "#555a5f",
              price: {
                rub: "550",
                tenge: "3200"
              }
            },
            {
              srcPart: "brown",
              name: "Коричневый",
              color: "#884535",
              price: {
                rub: "550",
                tenge: "3200"
              }
            },
            {
              srcPart: "orange",
              name: "Оранжевый",
              color: "#F3892B",
              price: {
                rub: "550",
                tenge: "3200"
              }
            },
            {
              srcPart: "red",
              name: "Красный",
              color: "#E32636",
              price: {
                rub: "550",
                tenge: "3200"
              }
            }
          ]
        }
      }
*/