<template>
  <section class="p-specifications" id="specifications">

    <div class="container">

      <div class="header wow animate__fadeInLeft">
        <h2>Наша специализация</h2>
        <p>У нас вы можете приобрести брусчатку любого типа, цвета и формы, а также заказать укладку брусчатки</p>
      </div>

      <ul class="layer wow animate__fadeInLeft">
        <li v-for="(product, index) in products" :key="index">
          <a @click="onSwitch(index)" :class="current == index ? 'show' : ''">{{ product.name.short }}</a>
        </li>
      </ul>
        
      <article class="products wow animate__fadeInUp">
        <div class="product" v-for="(product, index) in products" :key="product.name.short" :class="current === index ? 'show' : 'hide'">

          <div class="image">
            <img  :src="getImage(index)" alt="Product" />

            <div class="border">
              <p class="price">от {{ product.price }} тенге за кв.м</p>
            </div>
          </div>

          <div class="text">

            <h3>{{ product.name.long }}</h3>
            <p>{{ product.desc }}</p>

            <ul class="info">
              <li v-for="(info, index) in product.info" :key="index">
                {{ info.text }}: {{ info.value}}
              </li>
            </ul>

            <div class="action">
              <span class="line"></span>
              <h4>Какая брусчатка подойдёт вам?</h4>
              <span class="line"></span>
            </div>

            <button class="button" @click="onShow">Заказать консультацию</button>

          </div>

        </div>
      </article>
    </div>

  </section>
</template>

<script>

export default {
  name: "Specifications",

  data: () => {
    return {
      products: [],
      current: 0
    };
  },

  methods: {

    getImage(index) {
      return require(`./../../assets/promo/${this.products[index].img}`);
    },

    onSwitch(index) {
      if (index >= 0 && index <= this.products.length - 1) {
        this.current = index;
      }
    },
    onShow() {
      this.$emit("onChange", true);
    }

  },

  computed: {

    product() {
      return this.products[this.current];
    }

  },

  created() {
    this.products = this.$store.getters.getProducts;
  }
}

</script>