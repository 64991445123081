export default {

  state: {

    products: [
      {
        name: {
          short: "Прямойлинейный",
          long: "Большой прямойлинейный пласт"
        },
        info: [
          {
            text: "Размер (см)",
            value: "120 х 40 х 4"
          },
          {
            text: "Масса пласта (кг)",
            value: "32"
          },
          {
            text: "Кол-во пластов на поддоне (шт)",
            value: "18"
          }
        ],
        colors: [
          {
            text: "Серый",
            style: "#9E9DA0"
          },
          {
            text: "Чёрный",
            style: "#333333"
          },
          {
            text: "Коричневый",
            style: "#94701A"
          },
          {
            text: "Оранжевый",
            style: "#FFAF25"
          },
          {
            text: "Красный",
            style: "#CC5858"
          }
        ],
        desc: "Описание пласта суть, этого текста в том что бы описать лучшие свойства этого пласта",
        img: "product-1.png",
        calc: "calc-1.png",
        price: "2800"
      },
      {
        name: {
          short: "Элемент веерного",
          long: "Элемент веерного пласта"
        },
        info: [
          {
            text: "Размер (см)",
            value: "118 х 38 х 4"
          },
          {
            text: "Площадь (кв.м)",
            value: "0.45"
          },
          {
            text: "Масса пласта (кг)",
            value: "31"            
          },
          {
            text: "Кол-во пластов на поддоне (шт)",
            value: "30"
          }
        ],
        colors: [
          {
            text: "Серый",
            style: "#9E9DA0"
          },
          {
            text: "Чёрный",
            style: "#333333"
          },
          {
            text: "Коричневый",
            style: "#94701A"
          },
          {
            text: "Оранжевый",
            style: "#FFAF25"
          },
          {
            text: "Красный",
            style: "#CC5858"
          }
        ],
        desc: "Описание пласта суть, этого текста в том что бы описать лучшие свойства этого пласта",
        img: "product-2.jpg",
        calc: "calc-2.png",
        price: "2800"
      },
      {
        name: {
          short: "Веерный",
          long: "Веерный пласт"
        },
        info: [
          {
            text: "Размер (см)",
            value: "118 х 38 х 4"
          },
          {
            text: "Площадь (кв.м)",
            value: "0.45"
          },
          {
            text: "Масса пласта (кг)",
            value: "31"
          },
          {
            text: "Кол-во пластов на поддоне (шт)",
            value: "30"
          }
        ],
        colors: [
          {
            text: "Серый",
            style: "#9E9DA0"
          },
          {
            text: "Чёрный",
            style: "#333333"
          },
          {
            text: "Коричневый",
            style: "#94701A"
          },
          {
            text: "Оранжевый",
            style: "#FFAF25"
          },
          {
            text: "Красный",
            style: "#CC5858"
          }
        ],
        desc: "Описание пласта суть, этого текста в том что бы описать лучшие свойства этого пласта",
        img: "product-3.jpg",
        calc: "calc-3.png",
        price: "2800"
      },
      {
        name: {
          short: "Поребрик",
          long: "Поребрик (брдюр)"
        },
        info: [
          {
            text: "Размер (см)",
            value: "42 х 20 х 8"
          },
          {
            text: "Масса (кг)",
            value: "13"
          },
          {
            text: "Кол-во бордюров на поддоне (шт)",
            value: "78"
          }
        ],
        colors: [
          {
            text: "Серый",
            style: "#9E9DA0"
          },
          {
            text: "Чёрный",
            style: "#333333"
          },
          {
            text: "Коричневый",
            style: "#94701A"
          },
          {
            text: "Оранжевый",
            style: "#FFAF25"
          },
          {
            text: "Красный",
            style: "#CC5858"
          }
        ],
        desc: "Описание пласта суть, этого текста в том что бы описать лучшие свойства этого пласта",
        img: "product-4.jpg",
        calc: "calc-4.png",
        price: "550"
      },
      {
        name: {
          short: "Полукруг",
          long: "Полукруг"
        },
        info: [
          {
            text: "Размер (см)",
            value: "118 х 60 х 4"
          },
          {
            text: "Площадь (кв.м)",
            value: "0.79"
          },
          {
            text: "Масса пласта (кг)",
            value: "48"
          },
          {
            text: "Кол-во пластов на поддоне (шт)",
            value: "16"
          }
        ],
        colors: [
          {
            text: "Серый",
            style: "#9E9DA0"
          },
          {
            text: "Чёрный",
            style: "#333333"
          },
          {
            text: "Коричневый",
            style: "#94701A"
          },
          {
            text: "Оранжевый",
            style: "#FFAF25"
          },
          {
            text: "Красный",
            style: "#CC5858"
          }
        ],
        desc: "Описание пласта суть, этого текста в том что бы описать лучшие свойства этого пласта",
        img: "product-5.jpg",
        calc: "calc-5.png",
        price: "2800"
      }
    ]

  },

  getters: {

    getProducts(state) {
      return state.products;
    }

  }

};