export default {

  state: {
    fileUrl: {
      kzt: '/price-kzt.pdf',
      rub: '/price-rub.pdf'
    },
    contact: {
      place: 'Республика Казахстан г. Актобе, 312 Стрелковой дивизии 5а',
      number: [
        '8 (777) 133-97-79',
        '8 (701) 370-16-56',
        '8 (705) 397-35-40'
      ],
      mail: 'asiastones@mail.ru'
    }
  },

  getters: {
    file(state) {
      return state.fileUrl;
    },
    contact(state) {
      return state.contact;
    }
  }

};