<template>
  <div class="m-wrapper">

    <div class="phone" @click="alertBox">
      <img src="./../../assets/main/call.png" alt="Phone" />
    </div>

    <div class="header-wrapper">
      <app-gallery @onShowAlert="alertBox()"></app-gallery>
    </div>

    <main class="content">
      <app-stowage></app-stowage>
      <app-advantages></app-advantages>
      <app-production></app-production>
      <app-contact></app-contact>
      <app-about></app-about>
      <app-footer></app-footer>
    </main>
    
  </div>
</template>

<script>

import Swal from 'sweetalert2';
import axios from 'axios';

import Gallery from './Gallery.vue';
import Advantages from './Advantages.vue';
import Production from './Production.vue';
import Stowage from './Stowage.vue';
import Contact from './Contact.vue';
import About from './About.vue';
import Footer from './Footer.vue';

export default {

  data() {
    return {
      isAlert: false,
      timerID: null
    }
  },

  mounted() {
    new WOW({boxClass: 'wow', resetAnimation: true, animateClass: 'animate__animated'}).init();
  },

  methods: {
    toFormData: function(obj) {
        let formData = new FormData();
        for(let key in obj) {
            formData.append(key, obj[key]);
        }
        return formData;
    },

    alertBox: function () {

      if (this.isAlert) {
        return;
      } else {
        this.isAlert = true;
      }

      this.$fire({
        html: `
          <section class="popup">
            <h1>Остались ещё вопросы?</h1>
            <p>Заполните форму, и наш специалист свяжется с вами</p>

            <div class="form">
              <div class="input-block">
                <label for="name-field">Имя</label>
                <input id="name-field" type="text" placeholder="Введите ваше имя" pattern="[A-Za-zА-Яа-яЁё]">
              </div>

              <div class="input-block">
                <label for="phone-field">Телефон</label>
                <input id="phone-field" type="text" placeholder="Введите номер телефона">            
              </div>
            </div>

            <div class="or">
              <p class="line"></p>
              <p class="text">или</p>
              <p class="line"></p>
            </div>

            <div class="whatsapp">
              <p>Напишите нам на WhatsApp и наш сотрудник ответит на любые ваши вопросы</p>
              <a href="https://api.whatsapp.com/send?phone=77059173133" target="_blank">
                WhatsApp
              </a>
            </div>

          </section>
        `,
        padding: 30,
        confirmButtonText: 'Отправить', // Текст кнопки подтверждения
        showCancelButton: true, // Кнопка выхода из окна
        cancelButtonText: 'Закрыть',
        allowOutsideClick: false, // Запрет на закрытия уведомления кликом вне области контейнера уведомления
        allowEnterKey: false, // Нажатие на Enter не подтвердит уведомление
        preConfirm: () => {
          let name = document.getElementById('name-field').value,
              phone = document.getElementById('phone-field').value;

          let phoneRegExp = /^(\+)?(\(\d{2,3}\) ?\d|\d)(([ \-]?\d)|( ?\(\d{2,3}\) ?)){5,12}\d$/;

          if (name == '' || phone == '') {
            Swal.showValidationMessage(`Поля не могут быть пустыми`);
          } else if (name.search(/\d/) != -1) {
            Swal.showValidationMessage(`Имя не может содержать цифры`);
          } else if (!phoneRegExp.test(phone)) {
             Swal.showValidationMessage(`Неверный формат телефоного номера`);
          } else {
            return {
              name,
              phone,
              isConfirmed: true
            }
          }
          
        }
      }).then((result) => {

        let { value: data } = result;

        let vm = this;

        if (result.dismiss === 'cancel') {
          this.isAlert = false;
          return;
        }

        if (data.isConfirmed && this.isAlert) {

          const options = {
              method: 'POST',
              headers: { 'content-type': 'application/form-data' },
              data: vm.toFormData(data),
              url: 'http://as-aktobe.kz/feedback.php',
          };
    
          axios(options)
            .then(function (res) {
              Swal.fire('Успех', 'Сообщение успешно доставлено', 'success');
            })
            .catch(function (error) {
              Swal.fire('Ошибка', 'Некорректный запрос', 'error');
            });
        }

      });
    }
  },

  created() {
    this.timerID = window.setTimeout(() => {
      this.alertBox()
    }, 15000);
  },

  beforeRouteLeave(to, from, next) {
    let vm = this;
    window.clearTimeout(vm.timerID);
  },

  components: {
    appGallery: Gallery,
    appAdvantages: Advantages,
    appProduction: Production,
    appStowage: Stowage,
    appContact: Contact,
    appAbout: About,
    appFooter: Footer
  }

};
</script>

<style lang="scss">

  .swal2-container {
    z-index: 99999 !important;
  }

  body {
    padding-right: 0 !important;
  }

  section.popup {
    
    & >h1 {
      font: 700 1.4rem / 1.4rem Tahoma;
      margin-bottom: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    & > p {
      font: normal 1rem / 1.4rem Tahoma;
      margin-bottom: 15px;
      letter-spacing: 1px;
    }

    & > div.form {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      div.input-block {
        flex: 1 1 100%;

        display: flex;
        flex-direction: column;

        margin-bottom: 15px;


        label {
          font: normal 1rem / 1rem Tahoma;
          align-self: flex-start;
          margin-bottom: 10px;
        }

        input {

          padding: 10px;
          border-radius: 10px;

          border: solid 1px #cecece;
          outline: none;
          font: normal 0.9rem / 0.9rem Tahoma;
        }
      }
    }

    & > div.or {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      margin-bottom: 20px;

      p.line {
        flex: 1 1 auto;
        height: 1px;
        background-color: #cecece;
        border-radius: 5px;
      }

      p.text {
        padding: 0 20px;
        font: bold 1.2rem / 1.2rem Tahoma;
      }
    }

    & > div.whatsapp {

      p {
        font: normal 1rem / 1.4rem Tahoma;
        margin-bottom: 10px;
      }

      a {
        color: #188C18;
        font: bold 1.2rem / 1.4rem Montserrat;
        letter-spacing: 1px;

        transition: color .3s ease;

        &:hover {
          color: #3085d6;
        }
      }

    }
  }

  .swal2-validation-message {
    margin-top: 20px;
  }

</style>