<template>
  <section class="p-call">

    <div class="container">
      
      <div class="header">
        <h2 class="wow animate__fadeInLeft">Хотите узнать, сколько будет стоить укладка?</h2>
        <p class="wow animate__fadeInRight">Звоните нам прямо сейчас!</p>
        <p class="phone">8 (705) 3973540</p>
        <div class="or">
          <div class="line"></div>
          <p>или</p>
          <div class="line"></div>
        </div>
      </div>

      <fieldset class="form" v-if="!success">
        <legend>Заполните форму, и наш специалист свяжется с вами</legend>

        <form @submit="onSubmit" novalidate="true" >
          <input type="text" name="name" id="name" v-model="form.name" placeholder="Ваше имя">
          <input type="tel" name="text" id="tel" v-model="form.phone" placeholder="Ваш номер телефона">
          <input type="email" name="email" id="email" v-model="form.email" placeholder="Ваш Email">

          <button type="submit" class="request">Получить консультацию</button>
        </form>

      </fieldset>

      <div class="success" v-else>
        <h2>Запрос был успешно отправлен</h2>
        <p>В ближайшее время с вами свяжется наш специалист, удостоверьтесь что данные были введены коректны</p>

        <div class="form-data">
          <p>Ваше имя: <span>{{ form.name }}</span></p>
          <p>Ваше телефон: <span>{{ form.phone }}</span></p>
          <p>Ваше email: <span>{{ form.email }}</span></p>
        </div>
      </div>
      
    </div>

  </section>
</template>

<script>

export default {
  name: "Call",

  data: () => {
    return {
      form: {},
      success: false
    };
  },

  methods: {
    
    onSubmit(e) {
      e.preventDefault();

      let self = this;

      for (let prop in self.form) {

        if (self.form[prop] === "") {
          console.log(self.form[prop]);
          return;
        }
      }

      axios.post("/feedback.php", self.form).then(function (response) {
        self.success = response.status == 200 ? true : false;
      });

    }
  }

}

</script>