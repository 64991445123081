<template>
  <section class="contact wow animate__fadeIn" id="contact">

    <div class="price">
      <div class="choose-currency">
        <p :class="isCurrency('rub')" @click="setCurrency('rub')">RUB</p>
        <p :class="isCurrency('kzt')" @click="setCurrency('kzt')">KZT</p>
      </div>

      <a :href="getFile" target="_blank">
        <img src="./../../assets/main/pdf.png" alt="PDF" />
      </a>
      <p>Цены</p>
    </div>

    <div class="contact-icon">
      <img src="./../../assets/main/contact.svg" alt="Contact" @click="onShowContact" />
      <p>Контакты</p>
    </div>

    <div class="empty">

      <transition name="contact">
        <div class="contact" v-if="show.contact">
          <ul class="list">
            <li>
              <img src="./../../assets/main/contact-marker.png" alt="Marker" />
              <p>{{ getContact.place }}</p>
            </li>
            <li>
              <img src="./../../assets/main/contact-phone.png" alt="Phone" />
              <ul class="phone">
                <li v-for="(tel, index) in getContact.number" :key="index + 1">
                  {{ tel}}
                </li>
              </ul>
            </li>
            <li>
              <img src="./../../assets/main/contact-mail.png" alt="Mail" />
              <p>{{ getContact.mail }}</p>
            </li>
          </ul>
        </div>
      </transition>

      <transition name="video">
        <div class="video" v-if="show.video">
           <iframe width="300" height="300" src="https://www.youtube.com/embed/2_FgwolGiho" frameborder="0" allowfullscreen></iframe>
        </div>        
      </transition>

    </div>

    <div class="video-icon">
      <img src="./../../assets/main/video.svg" alt="Contact" @click="onShowVideo" />
      <p>Видео</p>
    </div>

  </section>
</template>

<script>
export default {

  data() {
    return {
      show: {
        video: false,
        contact: false
      },
      currency: 'kzt'
    };
  },

  methods: {
    onShowContact() {
      if (this.show.video) {
        this.show.video = false;
      }

      this.show.contact = !this.show.contact;
    },
    onShowVideo() {
      if (this.show.contact) {
        this.show.contact = false;
      }

      this.show.video = !this.show.video;
    },
    setCurrency(currency) {
      this.currency = (currency === this.currency) ? this.currency : currency;
    },
    isCurrency(currency) {
      return (currency === this.currency) ? 'choosed' : '';
    }
  },

  computed: {
    getContact() {
      return this.$store.getters.contact;
    },
    getFile() {
      return this.$store.getters.file[this.currency];
    }
  }

}
</script>

<style lang="scss">

  .contact-enter, .contact-leave-to, .video-enter, .video-leave-to {
    opacity: 0;
  }

  .contact-enter-active, .contact-leave-active, .video-enter-active, .video-leave-active {
    transition: opacity .5s ease .25s;
  }

  .contact-enter-to, .contact-leave, .video-enter-to, .video-leave {
    opacity: 1;
  }

</style>