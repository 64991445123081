export default {

  state: {
    sliderSrc: ['1.jpg', '2.jpeg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '8.jpg', '9.jpg', '10.jpg', '11.jpg', '12.jpg', '13.jpg', '14.jpg'],

    cardContent: {
      frontSide: {
        header: "Каменный ковёр",
        text: "«Каменный ковер» – это новый системный подход в производстве изделий для укладки (мощения) тротуаров и дорог, обустройства территории, ландшафтного дизайна.",
        button: "Узнать Больше"
      },

      backSide: {
        header: "Каменный ковёр",
        text: "Инновационная технология «Каменный ковер» - уникальная система мощения на гибких связях, посредством которой можно легко и быстро превратить любой участок земли в комфортное, эстетически приятное место, напоминающее старинные брусчатые мостовые.",
        button: "Назад"
      }
    },
  },

  getters: {
    cardContent(state) {
      return state.cardContent;
    },

    sliderSrc(state) {
      return state.sliderSrc;
    }
  }
};