<template>
  <section class="about wow animate__fadeIn" id="about">
    
    <article class="image">
      <div class="img">
        <p :style="imageSrc('a-1.jpg')"></p>
      </div>
      <div class="img">
        <p :style="imageSrc('a-3.jpg')"></p>
      </div>
      <div class="instagram">
        <a href="https://www.instagram.com/as_aktobe/" target="_blank">
          <img src="./../../assets/main/instagram-icon.svg" alt="Instagram" />
        </a>
        <p>Instagram</p>
      </div>
      <div class="img">
        <p :style="imageSrc('a-2.jpg')"></p>
      </div>
    </article>

    <article class="description">
      <div class="about">
        <h2>{{ getContent.header }}</h2>
        <p>{{ getContent.text1 }}</p>
        <p>{{ getContent.text2 }}</p>
        <p>{{ getContent.text3 }}</p>
        <p>{{ getContent.text4 }}</p>
      </div>
    </article>

  </section>
</template>

<script>
export default {

  methods: {
    getImgUrl(pic) {
      return require('./../../assets/main/' + pic);
    },
    
    imageSrc(src) {
      return {
        backgroundImage: 'url(' + this.getImgUrl(src) + ')'
      }
    }
  },

  computed: {
    getContent() {
      return this.$store.getters.content;
    }
  }

};
</script>