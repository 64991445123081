<template>
  <router-view></router-view>
</template>

<script>

export default {

  beforeUpdate() {
    console.log('leave');
  }

}

</script>