<template>
  <section id="production" class="production wow animate__fadeInRight">

    <article class="description">
      
      <div class="details-info">

        <h2>{{ getCurrentLayerDetails.header }}</h2>

        <ul>
          <li
            v-for="(text, index) in getCurrentLayerDetails.text"
            :key="index + 1"
          >
            {{ text }}
          </li>
        </ul>

      </div>

      <div class="layers-info">

        <div class="choose-currency">
          <ul class="currency-list">
            <li
              v-for="(cText, cCurrency, index) in getCurrency"
              @click="setCurrency(cCurrency)"
              :key="index + 1"
              :class="['currency-item', cCurrency == currency ? 'active' : false]"
            >{{ cCurrency }}</li>
          </ul>
        </div>


        <ul class="layers">
          <li
           v-for="(layer, index) in getCurrentLayerPrice.price"
           :key="index + 1"
           :style="layerImgStyle(layer.srcPart)"
           :class="['layer-item', color == layer.srcPart ? 'showing' : false]"
          >

            <div class="price">
              <span>{{ layer.price[getCurrency[currency]] }} {{ currencySymbol }}</span>
              <span v-if="getCurrentLayerPrice.amount == 'meters'">
                1 м<sup>2</sup>
              </span>
              <span v-else>
                1 шт
              </span>
            </div>

          </li>
        </ul>

        <div class="layer-option">

          <ul class="color-palette">
            <li
              v-for="(item, index) in getCurrentLayerPrice.price" 
              :key="index + 1"
              :style="setColorPalette(item.color)"
              @click="onColor(item.srcPart)"
            ></li>
          </ul>

        </div>

      </div>

    </article>

    <article class="slider">

      <ul class="slider-list">
        <li
          v-for="(obj, index) in getPriceList"
          :key="index + 1"
          :style="sliderImgStyle(obj.slider.src)"
          :class="['slider-item', current - 1 == index ? 'showing' : false]"
        ></li>
      </ul>
      
    </article>

    <article class="controller">

      <div class="image">
        <img src="./../../assets/main/a-4.jpg" alt="Image" />
      </div>

      <slider-switcher
        :total="getPriceList.length"
        @slideChange="onChangeSlide"
      ></slider-switcher>

    </article>

  </section>
</template>

<script>

import SliderSwitcher from './SliderSwitcher';

export default {

  data() {

    return {
      current: 1,
      currency: 'KZT',
      color: 'white'
    }

  },

  methods: {
    onChangeSlide(data) {
      this.current = data;
    },
    onColor(color) {
      this.color = color;
    },
    setCurrency(currency) {
      this.currency = currency;
    },
    setColorPalette(color) {
      return {
        backgroundColor: color
      }
    },
    layerImgStyle(src) {
      return {
        backgroundImage: "url(" + require('./../../assets/main/layer/' + src + '/' + this.getCurrentLayerPrice.src) + ")"
      }
    },
    sliderImgStyle(src) {
      return {
        backgroundImage: "url(" + require('./../../assets/main/' + src) + ")"
      }
    }
  },

  computed: {
    getPriceList() {
      return this.$store.getters.priceList;
    },
    getCurrency() {
      return this.$store.getters.currency;
    },
    getCurrentLayer() {
      return this.getPriceList[this.current - 1];
    },
    getCurrentLayerDetails() {
      return this.getCurrentLayer.details;
    },
    getCurrentLayerPrice() {
      return this.getCurrentLayer.layers;
    },
    getCurrentSlide() {
      return this.getCurrentLayer.slider;
    },
    currencySymbol() {
      return this.currency == "KZT" ? "₸" : "₽";
    }
  },

  components: {
    SliderSwitcher
  }

};
</script>