export default {

  state: {
    buildingProcess: [
      {
        src: "1.jpg",
        header: "Уплотнение основания",
        text: "Тщательное уплотнение основания является лучшей гарантией для долговечной и ровной поверхности тротуара."
      },
      {
        src: "2.jpg",
        header: "Выравнивание основания",
        text: "После того, как основание уплотнено, следует выровнять поверхность, используя ровную планку. Таким образом создается ровное основание для укладки пластов."
      },
      {
        src: "3.jpg",
        header: "Укладка элементов",
        text: "Теперь пласты можно уложить в форме выбранного узора. Используя возможность разделения элементов пластов, можно создать огромное множество узоров различных по конфигурации и по цвету."
      },
      {
        src: "4.jpg",
        header: "Отрезание элементов",
        text: "Элементы пласта можно отделять друг от друга с помощью ножа или кусачек."
      },
      {
        src: "5.jpg",
        header: "Заполнение швов",
        text: "Швы каменного ковра могут быть заполнены песком, песочно-цементной смесью, мелким гравием, мраморной крошкой или цементным раствором."
      },
      {
        src: "6.jpg",
        header: "Уплотнение готовой продукции",
        text: "После заполнения швов следует уплотнить тротуарную поверхность, используя виброплиту."
      }
    ],
    hideStowage: false
  },

  getters: {
    buildingProcess(state) {
      return state.buildingProcess;
    },
    showStowage(state) {
      return state.hideStowage;
    }
  },

  mutations: {
    hide(state) {
      state.hideStowage = !state.hideStowage;
    }
  }

};