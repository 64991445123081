<template>
    <section class="p-contact " id="contact">
      
        <div class="container">
            <div class="text">
                <h2>Наши контакты</h2>
                <p>Приезжайте к нам знакомиться</p>

                <ul class="menu">

                    <li>
                        <img  :src="require('./../../assets/promo/contact-phone.png')" alt="icon" >
                        <div class="data">
                            <h4>Телефон</h4>
                            <p>8 (701) 3035777</p>
                        </div>
                    </li>

                    <li>
                        <img  :src="require('./../../assets/promo/contact-email.png')" alt="icon" >
                        <div class="data">
                            <h4>email </h4>
                            <p>asiastones@mail.ru</p>
                        </div>
                    </li>

                    <li>
                        <img  :src="require('./../../assets/promo/contact-adress.png')" alt="icon" >
                        <div class="data">
                            <h4>Адрес</h4>
                            <p>Актобе, 312 Стрелковой дивизии 5а</p>
                        </div>
                    </li>

                </ul>
            </div>

            <div class="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7804.7527592473125!2d57.18636860307505!3d50.29726125231618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x41822177a8d260ad%3A0x11e36f207c67c94!2z0L_RgC3Rgi4gMzEyINCh0YLRgNC10LvQutC-0LLQvtC5INC00LjQstC40LfQuNC4IDUsINCQ0LrRgtC-0LHQtSwg0JrQsNC30LDRhdGB0YLQsNC9!5e0!3m2!1sru!2slv!4v1600677717094!5m2!1sru!2slv" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
        </div>

  </section>
</template>

<script>

export default {
  name: "Contact"
}

</script>