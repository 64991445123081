<template>
  <section class="p-guarantee" id="guarantee">

    <div class="container">
      <div class="image wow animate__fadeInLeftBig">
          <img  :src="require('./../../assets/promo/bg-guarantee.png')" alt="image" >
      </div>

      <div class="text wow animate__fadeInRightBig">
          <h2>Вы гарантированно получайете оригинальные матеариалы и честный сервис</h2>

          <p>Наши технология это "Carpet Stones" , который известен и применяется во всем мире и теперь выпускается в Казахстане и России. А это значит, что подделки и продукция неизвестного качества исключены.</p>
          <p>Мы забираем после строительства неиспользованные материалы и возвращаем вам за них деньги. Поэтому не в наших интересах продать вам лишнее.</p>
          <p>И, наконец, мы гарантируем вам укладку в соответствии с техническими требованиями каждого вида брусчатки. Если у вас своя бригада, то наш технический специалист бесплатно приедет на встречу с вами и вашими исполнителями для прояснения деталей и условий укладки.</p>
          <button @click="onShow()" class="request">Начать работу с нами</button>
      </div>
    </div>

  </section>
</template>

<script>

export default {
  name: "Guarantee",

  methods: {
    onShow() {
      this.$emit("onChange", true);
    }
  }
}

</script>