import Vue from 'vue';
import VueSimpleAlert from 'vue-simple-alert';

import App from './App.vue';
import router from './router';
import store from './store';

import './style.scss';

Vue.use(VueSimpleAlert);

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});