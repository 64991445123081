<template>
  <transition name="slideOut">
    <section class="stowage" id="stowage" v-if="showStowage">

      <article class="step"
        v-for="(item, index) in getStowage"
        :key="index + 1"
      >

        <template v-if="index < 2 || index > 3">
          <div class="image">
            <div class="img" :style="'background-image: url(' + getImgUrl(item.src) + ')'" alt="Этап Укладки" />
            <div class="counter">{{ index + 1 }}</div>
          </div>

          <div class="description">
            <h2>{{ item.header }}</h2>
            <p>{{ item.text }}</p>
          </div>
        </template>

        <template v-else>
          <div class="description">
            <h2>{{ item.header }}</h2>
            <p>{{ item.text }}</p>
          </div>

          <div class="image">
            <div class="img" :style="'background-image: url(' + getImgUrl(item.src) + ')'" alt="Этап Укладки" />
            <div class="counter reverse">{{ index + 1 }}</div>
          </div>
       </template>
            
      </article>

    </section>
  </transition>
</template>

<script>
export default {

  methods: {
    getImgUrl(src) {
      return require('./../../assets/main/stowage/' + src);
    },
  },

  computed: {
    getStowage() {
      return this.$store.getters.buildingProcess;
    },
    showStowage() {
      return this.$store.getters.showStowage;
    }
  }

}
</script>

<style lang="scss">

  .slideOut-enter {
    opacity: 0;
  }

  .slideOut-enter-active {
    transition: opacity 1s ease;
  }

  .slideOut-enter-to {
    opacity: 1;
  }

  .slideOut-leave {
    opacity: 1;
  }

  .slideOut-leave-active {
    transition: opacity 1s ease;
  }

  .slideOut-leave-to {
    opacity: 0;
  }

</style>