<template>
  <section class="p-about" id="about">

    <div class="line">
      <div class="arrow">
        <img draggable="false" src="./../../assets/promo/arrow-down.png" alt="arrow" />
      </div>
    </div>
      
    <div class="header wow animate__fadeInLeft">
      <h2>Кто мы такие</h2>
      <p>У нас есть всё что нужно для удобной и быстрой укладки брусчатки</p>
    </div>

    <section class="wrapper">
      <div class="container">

        <div class="image wow animate__zoomIn"></div>

        <article class="about">

          <div class="text">
            <h3 class="wow animate__backInRight">О нас</h3>
            <p class="wow animate__backInRight">Нашими силами покрыто уже более 1000 квадратных киллометров брусчатки. Производим разные цветовые пегменатция и формы для новой продукций. Мы ценим ваше доверие и делаем всё для улучшения качества нашей продукций.</p>

            <ul class="advantages wow animate__fadeInUp">
              <li>
                <div class="header">
                  <img  :src="require('./../../assets/promo/icon-about-1.png')" alt="icon" />
                  <h4>Шеф для укладки</h4>
                </div>
                <p>Для того, чтобы брусчатка служила долго, наш сотрудник выезжает к вам м контролирует правильность укладки ключевых моментов.</p>
              </li>

              <li>
                <div class="header">
                  <img  :src="require('./../../assets/promo/icon-about-2.png')" alt="icon" />
                  <h4>Проверенные технологий</h4>
                </div>
                <p>Вы приобретаете брусчатку, запатентованной технологий, качество которой подтверждено временем.</p>
              </li>

              <li>
                <div class="header">
                  <img  :src="require('./../../assets/promo/icon-about-3.png')" alt="icon" />
                  <h4>Консультация</h4>
                </div>
                <p>Мы поможем вам подобрать материалы учитывая технические требования и ваш бюджет.</p>
              </li>

              <li>
                <div class="header">
                  <img  :src="require('./../../assets/promo/icon-about-4.png')" alt="icon" />
                  <h4>Расчёт материалов</h4>
                </div>
                <p>Вы закупите ровно столько материалов, сколько нужно. В смете будет учтены все расходы.</p>
              </li>
            </ul>

          </div>

        </article>

      </div>
    </section>

    <div class="container wow animate__fadeInUp">

      <article class="info">

        <ul class="stats">
          <li>
            <h3>20</h3>
            <p>Дней укладки брусчатки</p>
          </li>
          <li>
            <h3>15</h3>
            <p>Лет опыта работы</p>
          </li>
          <li>
            <h3>24</h3>
            <p>Часа срок поставки</p>
          </li>
          <li>
            <h3>12</h3>
            <p>Типов разной продукций</p>
          </li>
        </ul>

        <div class="text">

          <div class="header">
            <div class="line"></div>
            <h3>Несколько слов о нас</h3>
          </div>
          
          <p>Вы строите двор или хотите перекрыть брусчаткой? Все, что вам понадобится для укладки, вы найдете в одном месте.</p>
          <p>Мы сделаем вам проект, рассчитаем материалы для укладки с малым коэффициентом отходов, подберем комплектующие и организуем доставку. А если вы поручите нам укладку, то вопрос брусчатки будет закрыт на несколько десятилетий.</p>
        </div>

      </article>

    </div>

  </section>
</template>

<script>
export default {
  name: "About"
}
</script>