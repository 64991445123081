<template>
  <div class="error-page">

    <div class="link">
      <button @click="backAtSite">На сайт</button>
    </div>
    
    <div class="info">
      <h2>Ошибка 404</h2>
      <p>Страница не найдена</p>
    </div>
    
  </div>
</template>

<script>
export default {

  methods: {
   backAtSite() {
     this.$router.push('/');
   }
  }

}
</script>