export default {

  state: {
    links: [
      {
        href: "#gallery",
        text: "Галерея",
        func: "onHideDescription"
      },
      {
        href: "#advantages",
        text: "Преимущества"
      },
      {
        href: '#production',
        text: 'Продукция'
      },
      {
        href: "#stowage",
        text: "Укладка",
        func: "onToStowage"
      },
      {
        href: "#contact",
        text: "Контакты"
      },
      {
        href: '#about',
        text: 'О компании'
      },
      {
        func: 'onAlertBox',
        text: 'Оставить заявку'
      }
    ] 
  },

  getters: {
    links(state) {
      return state.links;
    }
  }
};