import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

Vue.config.productionTip = process.env.NODE_ENV == "development" ? true : false;

import Gallery from './store/Gallery';
import Advantages from './store/Advantages';
import Production from './store/Production';
import Stowage from './store/Stowage';
import Contact from './store/Contact';
import About from './store/About';
import Footer from './store/Footer';

import Promo from './store/Promo';

export default new Vuex.Store({
  modules: [
    Gallery,
    Advantages,
    Production,
    Stowage,
    Contact,
    About,
    Footer,
    Promo
  ],
  strict: process.env.NODE_ENV == "development" ? true : false
});