<template>
    <footer class="p-footer">

      <div class="container">
        <p>Все права защищены (C) {{ new Date().getFullYear() }}</p>
      </div>
        
    </footer>
</template>

<script>

export default {
  name: "Footer"
}

</script>