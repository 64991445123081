<template>
    <header class="header">

      <section class="gallery" id="gallery">
        
        <ul class="slider-images">
          <li
            class="slider-item"
            v-for="(item, index) in sliderSrc"
            :key="index + 1"
            :style="slideImage(item)"
            :class="(current - 1 == index) ? 'showing' : false"
          ></li>
        </ul>

        <transition name="slide">
          <div v-if="!hideDescrition" class="logo">
            <img src="./../../assets/main/logo.png" alt="Logo" />
          </div>
        </transition>

        <transition name="slide">
          <div class="flip-container" v-if="!hideDescrition">

            <div class="flipper" :class="flipperSide">

              <div class="front" :class="isBackSideActive ? 'not-active-side' : 'active-side'">
                <h3><img src="./../../assets/main/galleryHeader.png" alt="Icon"></h3>
                <p>{{ cardContent.frontSide.text }}</p>
                <button @click="onChangeSide">{{ cardContent.frontSide.button }}</button>
              </div>
              
              <div class="back" :class="isBackSideActive ? 'active-side' : 'not-active-side'">
                <h3><img src="./../../assets/main/galleryHeader.png" alt="Icon"></h3>
                <p>{{ cardContent.backSide.text }}</p>
                <button @click="onChangeSide">{{ cardContent.backSide.button }}</button>
              </div>

            </div>

          </div>
        </transition>

        <transition name="slide">

          <div v-if="!hideDescrition" class="blue-block">
            <a href="#stowage">
              <img
                src="./../../assets/main/building-icon-link.png"
                alt="Building Process"
                @click="onToStowage"
              />
            </a>
            <p>Укладка</p>
          </div>

        </transition>

      </section>

      <nav class="menu">

        <div class="menu-mobile">
          <div class="logo">
            <img src="./../../assets/main/mobile-logo.png" alt="Logo" />
          </div>

          <div class="switcher" @click="changeState()">
            <img :src="getBurgerImage" alt="Menu" />
          </div>
        </div>

        <div class="inline-menu" :class="isActiveBurger">

          <ul class="menu-list">
            <li
              class="menu-item"
              v-for="(item, index) in menuLinks"
              :key="index + 1"
            >
              <a :href="item.href" @click="callBackAnchor(item.func)">{{ item.text }}</a>
            </li>
          </ul>

        </div>

        <slider-switcher
          :total="sliderSrc.length"
          :slideShow="true"
          :slideShowDelay="6000"
          @slideChange="onChangeSlide"
        >
          <template v-slot:right>
            <p
              class="clean-screen"
              :class="(hideDescrition) ? 'isActive' : 'notActive'"
              @click="onHideDescrition"
            ></p>
          </template>
        </slider-switcher>

        <div class="water-mark">
          <img src="./../../assets/main/water-mark.jpg" alt="Mark" />
        </div>

      </nav>

    </header>
</template>

<script>
import SliderSwitcher from './SliderSwitcher.vue';

export default {

  data() {
    return {
      burger: false,
      hideDescrition: false,
      isBackSideActive: false,
      current: 1
    };
  },

  methods: {
    slideImage(src) {
      return {
        backgroundImage: "url(" + require('./../../assets/main/slider/' + src) + ")"
      };
    },
    callBackAnchor(value) {
      if (value == 'onHideDescription') {
        this.onHideDescrition();
      } else if (value == 'onAlertBox') {
        this.$emit('onShowAlert');
      } else if (value == 'onToStowage') {
        this.onToStowage();
      }
    },
    onToStowage() {
      this.$store.commit('hide');
    },
    onChangeSlide(current) {
      this.current = current;
    },
    onHideDescrition() {
      this.hideDescrition = !this.hideDescrition;
    },
    onChangeSide() {
      this.isBackSideActive = !this.isBackSideActive;
    },
    changeState() {
      this.burger = !this.burger;
    }
  },

  computed: {
    flipperSide() {
      return (this.isBackSideActive) ? 'flip-rotate' : false;
    },
    cardContent() {
      return this.$store.getters.cardContent;
    },
    sliderSrc() {
      return this.$store.getters.sliderSrc;
    },
    menuLinks() {
      return this.$store.getters.links;
    },
    isActiveBurger() {
      return (this.burger === true) ? 'active' : false;
    },
    getBurgerImage() {
      return (this.burger === true) ? require('./../../assets/main/burger-hover.svg') : require('./../../assets/main/burger.svg');
    }
  },

  components: {
    SliderSwitcher
  }

};
</script>

<style lang="scss">

  .slide-leave, .slide-enter-to {
    opacity: 1;
  }

  .slide-leave-active, .slide-enter-active {
    transition: opacity 1s ease;
  }

  .slide-leave-to, .slide-enter {
    opacity: 0;
  }

</style>