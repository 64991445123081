<template>
  <div class="slider-switcher">

    <span class="left-icon">
      <slot name="left"></slot>
    </span>

    <transition name="slider">
      <p v-if="isLimit(this.total)" class="arrow right" @click="switcherSlider(1)"></p>
    </transition>

    <span class="current-slide">
      {{ current | sliderIndex }}
    </span>

    <span class="counts-slide">
      / {{ total | sliderIndex }}
    </span>

    <transition name="slider">
      <p v-if="isLimit(1)" class="arrow left" @click="switcherSlider(-1)"></p>
    </transition>

    <span class="right-icon">
      <slot name="right"></slot>
    </span>

  </div>
</template>

<script>
export default {

  data() {
    return {
      current: 1,
      timer: null
    };
  },

  props: {
    total: {
      type: Number,
      default: 1,
      required: true
    },
    slideShow: {
      type: Boolean,
      default: false
    },
    slideShowDelay: {
      type: Number,
      default: 5000
    }
  },

  created() {
    if (this.slideShow == true) {
      this.timer = setInterval(this.show, this.slideShowDelay);
    }
  },

  methods: {
    switcherSlider(number) {
      if (this.current + number > 0 && this.current + number <= this.total) {

        this.current += number; 
        this.$emit('slideChange', this.current);
      }

      if (this.slideShow == true) {    
        clearInterval(this.timer);
        this.timer = setInterval(this.show, this.slideShowDelay);
      }
    },

    isLimit(number) {
      return this.current == number ? false : true;
    },

    show() {
      if (this.current == this.total) {
        this.current = 1;
      } else {
        this.current += 1;
      }

      this.$emit('slideChange', this.current);
    }
  },

  filters: {
    sliderIndex(value) {
      return value >= 10 ? value : '0' + value;
    }
  }

}
</script>